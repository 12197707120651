<template>
    <div class="l-padded">
        <div class="l-stack l-gap-2 dashboard-maintenance-form">
            <h3 class="t-title">
                {{ $t('title') }}
            </h3>

            <MaintenanceForm />
        </div>
    </div>
</template>

<script>
import MaintenanceForm from '@/components/MaintenanceForm'

export default {
    name: 'DashboardMaintenanceFormView',
    components: {
        MaintenanceForm,
    },
}
</script>

<i18n>
{
    "en": {
        "title": "Maintenance"
    },
    "de": {
        "title": "Wartung"
    },
    "fr": {
        "title": "Maintenance"
    },
    "it": {
        "title": "Manutenzione"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-maintenance-form {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 600px;
}
</style>
